//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MobCultureSelector from "../components/mapComponents/common/MobCultureSelector.vue";
import HybridSelector from "../components/mapComponents/HybridSelector.vue";
import MapFilterGroupList from "../components/mapComponents/MapFilterGroupList.vue";
import MapFilterGroupListMulty from "../components/mapComponents/MapFilterGroupListMulty.vue";
import MapFilterGroupStack from "../components/mapComponents/MapFilterGroupStack.vue";
import MobSelector from "../components/mapComponents/MobSelector.vue";
import { mapGetters } from "vuex";
export default {
    metaInfo() {
        return {
            title: this.getCompareMeta,
        };
    },
    components: {
        MobCultureSelector,
        MapFilterGroupStack,
        MapFilterGroupListMulty,
        MapFilterGroupList,
        HybridSelector,
        MobSelector,
    },
    name: "ComparePage",
    props: ["currentCulture"],
    data() {
        return {
            compareSelectedCounters: { 1: [], 2: [] },
            compareSelectedCulture: null,

            viewIndex: 0,

            h: 0,
            currentWidth: 0,
            widthTrashhold: 991,
            mobTableTop: 0,

            // FILTER
            filter: false,

            years: null,
            cultures: null,
            regions: null,
            filterArgs: null,
            companies: null,
            hybrids: null,
            areas: null,

            selectedYear: null,
            selectedCultures: null,
            selectedRegions: null,
            selectedFilterArgs: null,
            selectedHybrids: null,
            selectedAreas: null,

            hybridsResult: null,

            loading: false,
        };
    },
    computed: {
        ...mapGetters([
            "getCompareMeta",
            "getLogo",
            "isLogin",
            "getBearerToken",
        ]),
        currentLayout() {
            if (this.currentWidth > this.widthTrashhold) return "desc";
            else return "mob";
        },
        currentList() {
            if (this.compareSelectedCulture) {
                return this.compareSelectedCounters[this.compareSelectedCulture]
                    .items;
            } else return [];
        },
        currentListIds() {
            const a = [];
            this.currentList.forEach((item) => {
                a.push(item.id);
            });
            return a;
        },
        currentViewList() {
            let end =
                this.currentList.length >= this.viewIndex + 4
                    ? this.viewIndex + 4
                    : this.currentList.length;
            return this.currentList.slice(this.viewIndex, end);
        },
        isStart() {
            return this.viewIndex < 1;
        },
        isEnd() {
            return !(this.viewIndex + 5 <= this.currentList.length);
        },
        tableHeight() {
            return this.h - 150;
        },
        firstFilterArg() {
            return this.filterArgs?.[1];
        },
        secondFilterArg() {
            return this.filterArgs?.[2];
        },
    },
    mounted() {
        if (!this.isLogin) {
            this.$router.push("/login");
        }

        this.axios
            .post("/api/comparison/get-data", {}, this.getBearerToken)
            .then((response) => {
                this.compareSelectedCounters = response.data.data;
                if (this.currentCulture)
                    this.compareSelectedCulture = this.currentCulture;
                else {
                    let found;
                    for (const key in this.compareSelectedCounters) {
                        found = key;
                        if (
                            Object.hasOwnProperty.call(
                                this.compareSelectedCounters,
                                key
                            )
                        ) {
                            const element = this.compareSelectedCounters[key];
                            if (element.items.length > 0) {
                                this.compareSelectedCulture = key;
                                return;
                            }
                        }
                    }
                    this.compareSelectedCulture = found;
                }
                const a = [];
                for (const key in this.compareSelectedCounters) {
                    if (
                        Object.hasOwnProperty.call(
                            this.compareSelectedCounters,
                            key
                        )
                    ) {
                        const element = this.compareSelectedCounters[key];
                        element.items.forEach((item) => {
                            a.push(item.id);
                        });
                    }
                }
                this.$store.commit("SETCOMPARECOUNT", a);
            });
        this.currentWidth = window.innerWidth;
        window.addEventListener("scroll", this.onScroll);
        window.addEventListener("resize", this.onResize);
    },
    methods: {
        onRemove(id) {
            this.axios
                .post(
                    "/api/comparison/remove",
                    {
                        id,
                    },
                    this.getBearerToken
                )
                .then(() => {
                    this.axios
                        .post(
                            "/api/comparison/get-data",
                            {},
                            this.getBearerToken
                        )
                        .then((response) => {
                            this.compareSelectedCounters = response.data.data;
                            const a = [];
                            for (const key in this.compareSelectedCounters) {
                                if (
                                    Object.hasOwnProperty.call(
                                        this.compareSelectedCounters,
                                        key
                                    )
                                ) {
                                    const element =
                                        this.compareSelectedCounters[key];
                                    element.items.forEach((item) => {
                                        a.push(item.id);
                                    });
                                }
                            }
                            this.$store.commit("SETCOMPARECOUNT", a);
                        });
                });
        },
        next() {
            if (this.viewIndex + 5 <= this.currentList.length) {
                this.viewIndex++;
            }
        },
        prev() {
            if (this.viewIndex > 0) {
                this.viewIndex--;
            }
        },
        openFilter() {
            this.years = null;
            this.cultures = null;
            this.regions = null;
            this.filterArgs = null;
            this.areas = null;
            this.hybridsResult = null;

            this.filter = true;
            this.axios
                .post(
                    "/api/comparison/filter",
                    {
                        culture_id: this.compareSelectedCulture,
                    },
                    this.getBearerToken
                )
                .then((response) => {
                    response = response.data.data;
                    this.years = response.filters.years;
                    this.cultures = response.filters.cultures;
                    this.regions = response.filters.regions;
                    this.filterArgs = response.filters.filters;
                    this.areas = response.filters.areas;

                    this.selectedYear = response.filters.years[0];

                    this.hybridsResult = response.hybrids;
                });
        },
        onScroll() {
            let a = this.$refs.mobTable;
            this.mobTableTop = a.getBoundingClientRect().top;
        },
        onResize() {
            if (window.innerWidth != this.currentWidth) {
                this.currentWidth = window.innerWidth;
            }
        },
        isEmpty(items, str) {
            let empty = true;
            items.forEach((item) => {
                const v = item[str];
                if (v == "-") {
                    empty = true;
                } else if (v != "") {
                    empty = false;
                }
            });
            return empty;
        },

        //Filter
        onYearSelect(val) {
            this.selectedYear = val;
            this.selectedCultures = null;
            this.selectedRegions = null;
            this.selectedFilterArgs = null;
            this.selectedHybrids = null;
            this.selectedAreas = null;
            this.filterUpdate();
        },
        onCultureSelect(val) {
            this.selectedCultures = val;
            this.selectedFilterArgs = null;
            this.rightPopup = false;
            this.filterUpdate();
        },
        onRegionSelect(val) {
            this.selectedRegions = val;
            this.filterUpdate();
        },
        onAreaSelect(val) {
            this.selectedAreas = val;
            this.filterUpdate();
        },
        onFilterArgsSelect(val) {
            this.selectedFilterArgs = val;
            this.filterUpdate();
        },
        filterUpdate() {
            this.loading = true;
            const args = {
                year: this.selectedYear,
                culture_id: this.compareSelectedCulture,
            };
            if (this.selectedRegions) args.region_id = this.selectedRegions;
            if (this.selectedAreas) args.area_id = this.selectedAreas;
            if (this.selectedHybrids) args.hybrid_id = this.selectedHybrids;
            if (this.selectedFilterArgs)
                args.attribute_ids = this.selectedFilterArgs;
            this.axios
                .post("/api/comparison/filter", args, this.getBearerToken)
                .then((response) => {
                    response = response.data.data;
                    this.mapRegions = response.regions;

                    this.years = response.filters.years || null;
                    this.cultures = response.filters.cultures || null;
                    this.regions = response.filters.regions || null;
                    this.filterArgs = response.filters.filters || null;
                    this.hybrids = response.filters.hybrids || null;
                    this.areas = response.filters.areas;

                    this.hybridsResult = response.hybrids;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        onHybridInclude(id) {
            this.loading = true;
            this.axios
                .post(
                    "/api/comparison/add",
                    {
                        id: id,
                    },
                    this.getBearerToken
                )
                .then(() => {
                    this.axios
                        .post(
                            "/api/comparison/get-data",
                            {},
                            this.getBearerToken
                        )
                        .then((response) => {
                            this.compareSelectedCounters = response.data.data;
                            this.loading = false;
                            this.filter = false;
                            const a = [];
                            for (const key in this.compareSelectedCounters) {
                                if (
                                    Object.hasOwnProperty.call(
                                        this.compareSelectedCounters,
                                        key
                                    )
                                ) {
                                    const element =
                                        this.compareSelectedCounters[key];
                                    element.items.forEach((item) => {
                                        a.push(item.id);
                                    });
                                }
                            }
                            this.$store.commit("SETCOMPARECOUNT", a);
                        })
                        .finaly(() => {
                            this.loading = false;
                        });
                });
        },
        onFilterClose() {
            this.filter = false;
            this.selectedYear = null;
            this.selectedCultures = null;
            this.selectedRegions = null;
            this.selectedFilterArgs = null;
            this.selectedHybrids = null;
            this.selectedAreas = null;
        },
    },
};
