//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import FormInput from "../Inputs/FormInput.vue";
export default {
    components: { FormInput },
    name: "LoginForm",
    data() {
        return {
            showError: false,
            process: false,
            name: "",
            fam: "",
            email: "",
            phone: "",

            password: "",
            passwordConfirm: "",
        };
    },
    validations() {
        return {
            name: { required, minLength: minLength(2) },
            fam: { minLength: minLength(3) },
            email: { required, email },
            // phone: { required, minLength: minLength(16) },
            password: { required, minLength: minLength(8) },
            passwordConfirm: {
                required,
                sameAsPassword: sameAs("password"),
            },
        };
    },
    methods: {
        async onsubmit() {
            this.showError = true;
            if (!this.process && !this.$v.$invalid) {
                try {
                    this.process = true;
                    const payload = {
                        name: this.name,
                        surname: this.fam,
                        email: this.email,
                        // phone: this.phone.replace(/[-+()]/g, ""),
                        password: this.password,
                        password_confirmation: this.passwordConfirm,
                    };
                    await this.axios.post("/api/auth/register", payload);
                    this.$store.dispatch("setPostRegEmail", this.email);
                    this.$router.push("/registersuccess");
                } catch (e) {
                    console.warn(e);
                } finally {
                    this.process = false;
                }
            }
        },
    },
};
