// import axios from 'axios'
import Cookie from "js-cookie";

const accountModule = {
    state: {
        accessToken: null,
        user: null,
        postRegEmail: null,
    },
    mutations: {
        changeAccessToken(state, token) {
            state.accessToken = token
        },
        changeUser(state, user) {
            state.user = user
        },
        changePostRegEmail(state, email) {
            state.postRegEmail = email
        },
    },
    actions: {
        setAccessToken({
            commit
        }, token) {
            commit('changeAccessToken', token)
            Cookie.set("accessToken", token);
            // axios.interceptors.request.use(config => {
            //     config.headers['Authorization'] = `Bearer ${token}`;
            //     return config;
            // });

        },
        setUser({
            commit
        }, user) {
            commit('changeUser', user)
        },
        async updateUser({
            commit,
            getters
        }) {
            const response = await this.$axios
                .post(
                    "/api/auth/user-profile",
                    {},
                    getters["getBearerToken"]
                )
            commit('changeUser', response.data.data)
        },
        setPostRegEmail({
            commit
        }, email) {
            commit('changePostRegEmail', email)
        },
    },
    getters: {
        isLogin(state) {
            if (state.accessToken && state.accessToken != 'null') {
                return true
            } else {
                return false
            }
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getBearerToken(state) {
            return {
                headers: {
                    Authorization: `Bearer ${state.accessToken}`
                },
            }
        },
        getUser(state) {
            return state.user
        },
        getPostRegEmail(state) {
            return state.postRegEmail
        }
    }
};

export default accountModule;