//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
    name: "CompareBnt",
    props: ["id"],
    computed: {
        ...mapGetters(["getCompareCount", "getBearerToken"]),
        isSelected() {
            if (this.getCompareCount) {
                return this.getCompareCount.indexOf(this.id) >= 0;
            } else return false;
        },
    },
    methods: {
        onClickLnk() {
            if (!this.isSelected) {
                // this.$emit("selectCompare", this.id);
                let a = this.getCompareCount;

                let i = a.indexOf(this.id);
                if (i == -1) {
                    a.push(this.id);
                } else {
                    a.splice(i, 1);
                }
                this.$store.commit("SETCOMPARECOUNT", a);

                this.axios
                    .post(
                        "/api/comparison/add",
                        {
                            id: this.id,
                        },
                        this.getBearerToken
                    )
                    .then((response) => {
                        console.log(response);
                    });
            } else {
                this.$emit("close");
                this.$router.push("/map/compare");
            }
        },
    },
};
