//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
    name: "SlugPage",
    data() {
        return {
            loaded: false,
            model: null,
            translate: null,
        };
    },
    computed: {
        ...mapGetters(["getLogo"]),
    },
    async mounted() {
        const {
            data: {
                data: { model, translate },
            },
        } = await this.axios.post("/api/page/get-by-slug", {
            slug: this.$route.params.slug,
        });
        this.model = model;
        this.translate = translate;
        this.loaded = true;
    },
};
