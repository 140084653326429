//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupIcoList from "../components/mapComponents/MapFilterGroupIcoList.vue";
import MapFilterGroupList from "../components/mapComponents/MapFilterGroupList.vue";
import MapFilterGroupStack from "../components/mapComponents/MapFilterGroupStack.vue";
import mapLines from "@/lines.js";
import mapIds from "@/IdDictionary.js";
import { stateLabels, areaLabels } from "@/IdDictionary.js";
import MapFilterGroupListMulty from "../components/mapComponents/MapFilterGroupListMulty.vue";
import RightPopup from "../components/mapComponents/RightPopup.vue";
import MobSelector from "../components/mapComponents/MobSelector.vue";
import { mapGetters } from "vuex";
export default {
    metaInfo() {
        return {
            title: this.getMapMeta,
        };
    },
    components: {
        MapFilterGroupStack,
        MapFilterGroupIcoList,
        MapFilterGroupList,
        MapFilterGroupListMulty,
        RightPopup,
        MobSelector,
    },
    name: "MapPage",
    data() {
        return {
            currentWidth: null,
            widthTrashhold: 991,
            mapWait: false,

            hideTimer: null,

            loading: true,
            zoom_ratio: 1,
            zoomChangeVal: 0.7,

            hidePaths: true,

            mapRegions: null,

            years: null,
            cultures: null,
            regions: null,
            filterArgs: null,
            companies: null,
            hybrids: null,

            selectedYear: null,
            selectedCultures: null,
            selectedRegions: null,
            selectedFilterArgs: null,
            selectedHybrids: null,

            rightPopupData: null,
            rightPopup: false,

            mobFilter: false,
            selectedCompare: [],

            regionName: "",
        };
    },
    created() {
        if (!this.isLogin) {
            this.$router.push("/login");
        }

        this.currentWidth = window.innerWidth;
        window.addEventListener("resize", this.resizeWidth);
    },
    mounted() {
        this.axios
            .post("/api/comparison/get-data", {}, this.getBearerToken)
            .then((response) => {
                response = response.data.data;
                for (const key in response) {
                    if (Object.hasOwnProperty.call(response, key)) {
                        const element = response[key];
                        element.items.forEach((item) => {
                            this.selectedCompare.push(item.id);
                        });
                    }
                }
                this.$store.commit("SETCOMPARECOUNT", this.selectedCompare);
            });
        this.axios
            .post(
                "/api/filter/get-data",
                {
                    lang: "uk",
                },
                this.getBearerToken
            )
            .then((response) => {
                response = response.data.data;
                this.years = response.years;
                this.cultures = response.cultures;
                this.regions = response.regions;

                this.selectedYear = response.years[0];

                this.filterUpdate();
                this.onMounted();
            });
    },
    watch: {
        state_specific(newValue) {
            Object.assign(this.mapdata.state_specific, newValue);
            if (this.countrymap.refresh) {
                this.countrymap.refresh();
            }
        },
    },
    computed: {
        ...mapGetters([
            "getLogo",
            "getMapMeta",
            // "compareCount",
            "getCompareLength",
            "isLogin",
            "getBearerToken",
        ]),
        currentLayout() {
            if (!this.mapWait) {
                if (this.currentWidth > this.widthTrashhold) return "desc";
                else return "mob";
            } else return null;
        },
        hidePopup() {
            return !(!this.selectedCultures && !this.selectedRegions);
        },
        countrymap() {
            return window.simplemaps_countrymap;
        },
        mapdata() {
            return window.simplemaps_countrymap_mapdata;
        },
        mapinfo() {
            return window.simplemaps_countrymap_mapinfo;
        },
        state_specific() {
            let ssp = {
                UKR283: { name: "AР Крим" },
                UKR284: { name: "Миколаївська область" },
                UKR285: { name: "Чернігівська область" },
                UKR286: { name: "Рівненська область" },
                UKR288: { name: "Чернівецька область" },
                UKR289: { name: "Івано-Франківська область" },
                UKR290: { name: "Хмельницька область" },
                UKR291: { name: "Львівська область" },
                UKR292: { name: "Тернопільська область" },
                UKR293: { name: "Закарпатська область" },
                UKR318: { name: "Волинська область" },
                UKR319: { name: "Черкаська область" },
                UKR320: { name: "Кіровоградська область" },
                UKR321: { name: "Київська область" },
                UKR322: { name: "Одеська область" },
                UKR323: { name: "Вінницька область" },
                UKR324: { name: "Житомирська область" },
                UKR325: { name: "Сумська область" },
                UKR326: { name: "Дніпропетровська область" },
                UKR327: { name: "Донецька область" },
                UKR328: { name: "Харківська область" },
                UKR329: { name: "Луганська область" },
                UKR330: { name: "Полтавська область" },
                UKR331: { name: "Запорізька область" },
                UKR4826: { name: "Місто Київ" },
                UKR4827: { name: "Херсонська область" },
                UKR5482: { name: "Севастопольська область" },
            };
            if (this.mapRegions) {
                for (const key in ssp) {
                    if (Object.hasOwnProperty.call(ssp, key)) {
                        if (this.mapRegions[mapIds[key]]) {
                            let html = `<div class="ppTitle">${ssp[key].name}</div><div class="ppText">Максимальна врожайність</div><div class="items">`;
                            if (this.mapRegions[mapIds[key]].cultures) {
                                for (const cultureKey in this.mapRegions[
                                    mapIds[key]
                                ].cultures) {
                                    if (
                                        Object.hasOwnProperty.call(
                                            this.mapRegions[mapIds[key]]
                                                .cultures,
                                            cultureKey
                                        )
                                    ) {
                                        const element =
                                            this.mapRegions[mapIds[key]]
                                                .cultures[cultureKey];
                                        html += `<div class="item"><div class="l"><img src="${this.path(
                                            element.culture_image
                                        )}" alt="" />${
                                            element.culture_name
                                        }</div><div class="r">${
                                            element.max_crop_capacity
                                        }<span>ц/га</span></div></div>`;
                                    }
                                }
                            } else if (this.mapRegions[mapIds[key]].culture) {
                                const element =
                                    this.mapRegions[mapIds[key]].culture;
                                html += `<div class="item"><div class="l"><img src="${this.path(
                                    element.culture_image
                                )}" alt="" />${
                                    element.culture_name
                                }</div><div class="r">${
                                    element.max_crop_capacity
                                }<span>ц/га</span></div></div>`;
                            }
                            html += `</div>`;
                            ssp[key].description = html;
                        } else {
                            ssp[
                                key
                            ].description = `<div class="ppTitle">${ssp[key].name}</div><div class="ppText">За вибраними фільтрами немає даних. Будь ласка, оберіть інші показники в фільтрах.</div>`;
                        }
                    }
                }
            }
            return ssp;
        },
        labels() {
            const arr = [];
            if (this.companies) {
                for (const key in this.companies) {
                    if (Object.hasOwnProperty.call(this.companies, key)) {
                        const el = this.companies[key];
                        arr.push({
                            id: el.id,
                            title: el.name,
                            lat: el.coords.lat,
                            lng: el.coords.lng,
                            culture:
                                el.cultures[0] ||
                                el.cultures[1] ||
                                el.cultures[2],
                        });
                    }
                }
            }
            return arr;
        },
        stateLabels() {
            if (this.zoom_ratio < this.zoomChangeVal) {
                return areaLabels();
            } else {
                return stateLabels;
            }
        },
        areasLabelClass() {
            if (this.zoom_ratio < this.zoomChangeVal) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        onYearSelect(val) {
            this.selectedYear = val;
            this.selectedCultures = null;
            this.selectedRegions = null;
            this.selectedFilterArgs = null;
            this.selectedHybrids = null;
            this.rightPopup = false;
            this.filterUpdate();
        },
        onCultureSelect(val) {
            this.selectedCultures = val;
            this.selectedFilterArgs = null;
            this.selectedHybrids = null;
            this.rightPopup = false;
            this.filterUpdate();
        },
        onRegionSelect(val) {
            this.selectedRegions = val;
            this.rightPopup = false;
            if (val) {
                let mapId;
                for (const key in mapIds) {
                    if (Object.hasOwnProperty.call(mapIds, key)) {
                        const element = mapIds[key];
                        if (element == val && key != "UKR4826") {
                            mapId = key;
                            this.regionName =
                                this.countrymap.mapdata.state_specific[
                                    key
                                ].name;
                        }
                    }
                }
                this.countrymap.state_zoom(mapId, () => {});
            } else {
                this.regionName = "";
                this.countrymap.back();
            }
            this.filterUpdate();
        },
        onHybridSelect(val) {
            this.selectedHybrids = val;
            this.rightPopup = false;
            this.filterUpdate();
        },
        onFilterArgsSelect(val) {
            this.selectedFilterArgs = val;
            this.rightPopup = false;
            this.filterUpdate();
        },
        filterUpdate() {
            this.loading = true;
            const args = { year: this.selectedYear };
            if (this.selectedCultures) args.culture_id = this.selectedCultures;
            if (this.selectedRegions) args.region_id = this.selectedRegions;
            if (this.selectedHybrids) args.hybrid_id = this.selectedHybrids;
            if (this.selectedFilterArgs)
                args.attribute_ids = this.selectedFilterArgs;

            this.axios
                .post("/api/map/get-data", args, this.getBearerToken)
                .then((response) => {
                    response = response.data.data;
                    if (response.regions) this.mapRegions = response.regions;

                    this.years = response.filters.years || null;
                    this.cultures = response.filters.cultures || null;
                    this.regions = response.filters.regions || null;
                    this.filterArgs = response.filters.filters || null;
                    this.hybrids = response.filters.hybrids || null;

                    if (response.res_type == "only_year_and_culture") {
                        const arr = [];
                        for (const key in response.regions) {
                            if (
                                Object.hasOwnProperty.call(
                                    response.regions,
                                    key
                                )
                            ) {
                                const el = response.regions[key];
                                arr.push({
                                    region: key,
                                    id: el.company_id,
                                    title: el.name,
                                    coords: el.coords,
                                    cultures: {
                                        1: el.culture,
                                    },
                                });
                            }
                        }
                        this.companies = arr;
                    } else {
                        this.companies = response.companies || null;
                    }

                    this.updateLocs();
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        flushFilter() {
            this.selectedCultures = null;
            this.selectedRegions = null;
            this.selectedHybrids = null;
            this.selectedFilterArgs = null;
            this.countrymap.back();
            this.filterUpdate();
        },

        resizeWidth() {
            if (window.innerWidth != this.currentWidth) {
                if (
                    (this.currentWidth < this.widthTrashhold &&
                        window.innerWidth > this.widthTrashhold) ||
                    (this.currentWidth > this.widthTrashhold &&
                        window.innerWidth < this.widthTrashhold)
                ) {
                    this.mapWait = true;
                    setTimeout(() => {
                        this.mapWait = false;
                    }, 1000);
                }
                this.currentWidth = window.innerWidth;
                clearTimeout(this.hideTimer);
                this.hideTimer = setTimeout(() => {
                    this.onMounted();
                }, 1000);
            }
        },
        onZoom() {
            this.zoom_ratio = this.countrymap.zoom_ratio;
            if (this.zoom_ratio < this.zoomChangeVal) {
                this.hidePaths = true;
                this.updateLocs();
            } else {
                this.hidePaths = false;
                this.updateLocs();
            }
            this.mapdata.labels = this.stateLabels;

            if (this.countrymap.refresh) this.countrymap.refresh();
        },
        onHover(id) {
            this.mapdata.locations[
                id
            ].image_url = `${process.env.VUE_APP_URL}get-image?culture=${this.labels[id].culture.culture_id}&cropcapacity=${this.labels[id].culture.max_crop_capacity}&type=2&company=${this.labels[id].id}`;
            this.mapdata.locations[id].size = 133;
            if (this.countrymap.refresh) this.countrymap.refresh();
        },
        onLeave(id) {
            this.mapdata.locations[
                id
            ].image_url = `${process.env.VUE_APP_URL}get-image?culture=${this.mapdata.locations[id].culture.culture_id}&cropcapacity=${this.mapdata.locations[id].culture.max_crop_capacity}&type=1&company=${this.mapdata.locations[id].id}`;
            this.mapdata.locations[id].size = 70;
            if (this.countrymap.refresh) this.countrymap.refresh();
        },
        updateLocs(id = null) {
            const locs = [];
            this.labels.forEach((item) => {
                const o = {};
                o.lat = item.lat;
                o.lng = item.lng;
                o.id = item.id;
                o.url = null;
                o.name = item.title;
                o.description = "";
                o.type = "image";
                o.inactive = "no";
                if (item.id == id) {
                    o.image_url = `${process.env.VUE_APP_URL}get-image?culture=${item.culture.culture_id}&cropcapacity=${item.culture.max_crop_capacity}&type=3&company=${item.id}`;
                    o.size =
                        this.currentWidth > this.widthTrashhold ? 133 : 173;
                } else {
                    o.image_hover_url = `${process.env.VUE_APP_URL}get-image?culture=${item.culture.culture_id}&cropcapacity=${item.culture.max_crop_capacity}&type=2&company=${item.id}`;
                    o.image_url = `${process.env.VUE_APP_URL}get-image?culture=${item.culture.culture_id}&cropcapacity=${item.culture.max_crop_capacity}&type=1&company=${item.id}`;
                    o.size = this.currentWidth > this.widthTrashhold ? 70 : 110;
                }
                o.image_position = "bottom-center";
                o.status = item.status;
                o.scale = "no";
                locs.push(o);
            });
            this.mapdata.locations = locs;
            if (this.countrymap.refresh) this.countrymap.refresh();
        },
        click_location(e) {
            const id = this.mapdata.locations[e].id;
            this.updateLocs(id);
            const args = { year: this.selectedYear, company_id: id };
            args.culture_id = this.labels[e].culture.culture_id;
            if (this.selectedRegions) args.region_id = this.selectedRegions;
            else args.region_id = this.companies[e].region;
            if (this.selectedHybrids) args.hybrid_id = this.selectedHybrids;
            this.axios
                .post("/api/map/get-marker-info", args, this.getBearerToken)
                .then((response) => {
                    response = response.data.data;
                    this.rightPopupData = response;
                    this.rightPopup = true;
                })
                .catch((e) => {
                    alert(e);
                });
        },
        onMounted() {
            this.countrymap.hooks.click_location = (e) => {
                this.click_location(e);
            };
            this.countrymap.hooks.click_state = (e) => {
                this.countrymap.state_zoom(e);
            };
            this.countrymap.hooks.zooming_complete = () => {
                this.onZoom();
            };
            this.countrymap.hooks.zoomable_click_state = (id) => {
                let a = this.regions.find((el) => el.id == mapIds[id]);
                this.regionName =
                    this.countrymap.mapdata.state_specific[id].name;
                if (a) {
                    this.selectedRegions = mapIds[id];
                    this.rightPopup = false;
                    this.filterUpdate();
                }
            };
            this.countrymap.hooks.back = () => {
                this.selectedRegions = null;
                this.filterUpdate();
            };
            // this.countrymap.hooks.over_location = (id) => {
            //     this.onHover(id);
            // };
            // this.countrymap.hooks.out_location = (id) => {
            //     this.onLeave(id);
            // };

            let main_settings = {
                // width,
                state_url: "",
                state_color: "#D9E1F2",
                state_hover_color: "#8fbc4b",
                border_color: "#fff",
                border_size: "1",
                location_size: "30",

                // Labels
                label_color: "#5F6F8F",
                label_hover_color: "#fff",
                label_size: "9",
                label_font: "Inter",
                hide_labels: "no",
                hide_eastern_labels: "no",
            };
            if (this.currentWidth > this.widthTrashhold) {
                main_settings.width = "responsive";
                window.simplemaps_countrymap_mapinfo.initial_view = {
                    x2: 1008.99,
                    x: 0.99,
                    y2: 675.69,
                    y: 0.99,
                };
            } else {
                if (window.innerWidth > window.innerHeight) {
                    main_settings.width = "responsive";
                    window.simplemaps_countrymap_mapinfo.initial_view = {
                        x2: 1008.99,
                        x: 0.99,
                        y2: 675.69,
                        y: 0.99,
                    };
                } else {
                    // width = (this.$refs.mapOverlay.clientHeight * 691) / 468;
                    window.simplemaps_countrymap_mapinfo.initial_view = {
                        x2: this.currentWidth,
                        x: 0.99,
                        y2: this.$refs.mapOverlay.clientHeight,
                        y: 0.99,
                    };
                }
            }
            Object.assign(this.mapdata.main_settings, main_settings);

            Object.assign(this.mapdata.labels, this.stateLabels);

            this.updateLocs();

            Object.assign(this.mapdata.state_specific, this.state_specific);

            // console.log(mapLines());
            this.mapdata.lines = mapLines();

            this.countrymap.load();
        },

        onRightPopupClose() {
            this.rightPopup = false;
            this.updateLocs();
        },
    },
};
