import Vue from "vue";
import VueRouter from "vue-router";
import defaultLayout from "@/layouts/Default.vue";
import map from "@/views/map.vue";
import mainPage from "@/views/mainPage.vue";
import comparePage from "@/views/comparePage.vue";
import loginPage from "@/views/loginPage.vue";
import registerPage from "@/views/registerPage.vue";
import registerSuccess from "@/views/registerSuccess.vue";
import resetPasswordPage from "@/views/resetPasswordPage.vue";
import resetSendSuccess from "@/views/resetSendSuccess.vue";
import confirmEmail from "@/views/confirmEmail.vue";
import passwordChangePage from "@/views/passwordChangePage.vue";
import SlugPage from "@/views/SlugPage.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "",
        component: defaultLayout,
        children: [
            {
                path: "/:locale(ru|uk|en)?",
                name: "mainPage",
                component: mainPage,
                children: [
                    {
                        path: "/:locale(ru|uk|en)?/login",
                        name: "login",
                        component: loginPage,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/register",
                        name: "register",
                        component: registerPage,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/registersuccess",
                        name: "registerSuccess",
                        component: registerSuccess,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/resetpassword",
                        name: "resetPasswordPage",
                        component: resetPasswordPage,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/resetsendsuccess",
                        name: "resetSendSuccess",
                        component: resetSendSuccess,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/user-email-confirm/token/:token",
                        name: "confirmEmail",
                        component: confirmEmail,
                    },
                    {
                        path: "/:locale(ru|uk|en)?/password-reset/token/:token",
                        name: "passwordChangePage",
                        component: passwordChangePage,
                    },
                ]
            },
            {
                path: "/:locale(ru|uk|en)?/map",
                name: "map",
                component: map,
            },
            {
                path: "/:locale(ru|uk|en)?/map/compare",
                name: "compare",
                component: comparePage,
            },
            {
                path: "/:locale(ru|uk|en)?/:slug",
                name: "SlugPage",
                component: SlugPage,
            },
            // {
            //     path: "/:locale(ru|uk|en)?/def",
            //     name: "def",
            //     component: page,
            // },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition || (to.name == "events" && from.name == "events"))
            return savedPosition;
        return {
            x: 0,
            y: 0,
        };
    },
    routes,
});

export default router;
