//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { adapter } from "@/utils.js";
// import SiteHeader from "../components/parts/SiteHeader.vue";
// import SiteFooter from "../components/parts/SiteFooter.vue";
// import BurgerMenu from "../components/parts/BurgerMenu.vue";
import Cookie from "js-cookie";
import CookieMessage from "../components/CookieMessage.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        CookieMessage,
        // SiteHeader,
        // SiteFooter,
        // BurgerMenu,
        // CookieMessage,
    },
    data() {
        return {
            burgerShown: false,
            cookieShown: false,

            userLoaded: false,
        };
    },
    computed: {
        ...mapGetters(["getBearerToken"]),
        routeKey() {
            switch (this.$route.name) {
                case "events":
                    return "events";
                default:
                    return this.$route.fullPath;
            }
        },
    },
    methods: {
        onCookieAccept() {
            Cookie.set("cookieAccept", true);
            this.cookieShown = false;
        },
    },
    created() {
        const token = Cookie.get("accessToken", token);
        if (token) {
            const Bearer = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            this.axios
                .post("/api/auth/get-user", {}, Bearer)
                .then(({ data: { data } }) => {
                    if (data.status == 2) {
                        this.$store.dispatch("setAccessToken", token);
                        this.$store.dispatch("setUser", data);
                    } else {
                        this.$store.dispatch("setAccessToken", null);
                        this.$store.dispatch("setUser", null);
                    }
                    this.userLoaded = true;
                })
                .catch(() => {
                    this.$store.dispatch("setAccessToken", null);
                    this.$store.dispatch("setUser", null);
                    if (
                        this.$router.name == `name` ||
                        this.$router.name == `compare`
                    ) {
                        this.$router.push("/login");
                    }
                    this.userLoaded = true;
                });
        } else {
            if (this.$router.name == `name` || this.$router.name == `compare`) {
                this.$router.push("/login");
            }
            this.userLoaded = true;
        }
    },
    mounted() {
        this.axios
            .post(
                "/api/settings/all",
                {
                    lang: this.locale,
                },
                this.getBearerToken
            )
            .then((result) => {
                const data = result.data.data;
                this.$store.commit("SETOPTIONS", data);
            });
        this.axios
            .post(
                "/api/menu/get-by-ids",
                {
                    lang: this.locale,
                    ids: [1, 21],
                },
                this.getBearerToken
            )
            .then((result) => {
                const data = result.data.data;
                this.$store.commit("SETHEADERMENU", data.items[1]);
                this.$store.commit("SETFOOTERMENU", data.items[21]);
            });
        setTimeout(() => {
            if (!Cookie.get("cookieAccept")) {
                this.cookieShown = true;
            }
        }, 1000);
    },
};
